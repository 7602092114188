import { EClientsAction } from './clients-constants';
import * as asyncActions from './clients-actions';
import slice from './clients-slice';

export { EClientsAction };
export const { name, reducer: clientsReducer } = slice;
export const {
  getClients,
  getClinicAndSoloGeneralInformation,
  getClinicAndSoloDetailStatistic,
  getClinicAndSoloSubscriptionInfo,
  getClinicAndSoloSubscriptionChangeLog,
  getClinicAndSoloSubscriptionPaymentLog,
  getInvoiceDetail,
  getIndividualClientInfo,
} = asyncActions;
