import { Space, Spin, Form, Input, Button, Alert, Card, Row, Col, Divider, Modal, Breadcrumb } from 'antd';
import CommonContent from 'src/components/containers/CommonContent';
import Container from 'src/components/containers/Container';
import StatusBox from 'src/components/status-box';
import Tabs from 'src/components/tabs';
import { BaseText } from 'src/components/text';
import { useEffect, useState, useCallback } from 'react';
import { useAppDispatch } from 'src/stores';
import { useParams, useNavigate } from 'react-router-dom';
import './IndividualClientDetail.scss';
import { getIndividualClientInfo, updateClientInfo } from 'src/stores/clients/clients-actions';
import { unwrapResult } from '@reduxjs/toolkit';
import PractitionersTable from './components/PractitionersTable/PractitionersTable';
import { parsePhoneNumber, isValidPhoneNumber, getExampleNumber, CountryCode } from 'libphonenumber-js';
import debounce from 'lodash/debounce';
import examples from 'libphonenumber-js/examples.mobile.json'; // For example numbers

enum EIndividualClientDetailTabKey {
  GENERAL_INFORMATION = 'general-information',
  PRACTITIONERS = 'practitioners',
}

const INDIVIDUAL_CLIENT_DETAIL_TAB = [
  {
    label: 'General Information',
    key: EIndividualClientDetailTabKey.GENERAL_INFORMATION,
  },
  {
    label: 'Practitioners',
    key: EIndividualClientDetailTabKey.PRACTITIONERS,
  },
];

interface IClientGeneralInfo {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  status: string;
  createdAt: string;
  totalPractitioners: number;
  practitioners: {
    id: string;
    email: string;
    status: string;
    createdAt: string;
    practiceName?: string;
    practitionerFirstName: string;
    practitionerLastName: string;
  }[];
}

const IndividualClientDetail = () => {
  const dispatch = useAppDispatch();
  const { clientId } = useParams() as { clientId: string };
  const navigate = useNavigate();

  const [currentTabKey, setCurrentTabKey] = useState<EIndividualClientDetailTabKey>(
    EIndividualClientDetailTabKey.GENERAL_INFORMATION
  );
  const [generalInformation, setGeneralInformation] = useState<IClientGeneralInfo>();

  const handleChangeTab = (activeKey: string) => {
    setCurrentTabKey(activeKey as EIndividualClientDetailTabKey);
  };

  const GeneralInformation = ({ info, onUpdate }: { info: IClientGeneralInfo; onUpdate: () => void }) => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [isEditingEmail, setIsEditingEmail] = useState(false);
    const [error, setError] = useState<string>();
    const [emailValidateStatus, setEmailValidateStatus] = useState<'' | 'success' | 'warning' | 'error' | 'validating'>('');

    const validateEmail = useCallback((_: any, value: string) => {
      if (!value) {
        setEmailValidateStatus('error');
        return Promise.reject('Email is required');
      }

      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      
      if (!emailRegex.test(value)) {
        setEmailValidateStatus('error');
        return Promise.reject('Please enter a valid email address');
      }

      setEmailValidateStatus('success');
      return Promise.resolve();
    }, []);

    const debouncedEmailValidation = useCallback(
      debounce((value: string) => {
        setEmailValidateStatus('validating');
        validateEmail(null, value).catch((err) => console.debug('Email validation error:', err));
      }, 300),
      []
    );

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      debouncedEmailValidation(value);
    };

    const handleSubmit = async (values: { email: string }) => {
      try {
        const emailValid = await validateEmail(null, values.email);
        setError(undefined);

        Modal.confirm({
          title: 'Change Login Email',
          content: 'Changing the email address will affect how this client logs into their account. Are you sure you want to continue?',
          okText: 'Yes, Change Email',
          cancelText: 'Cancel',
          onOk: async () => {
            try {
              await dispatch(updateClientInfo({ 
                clientId: info.id, 
                email: values.email.trim(),
                phone: info.phone
              })).unwrap();
              
              setIsEditingEmail(false);
              onUpdate();
            } catch (error: any) {
              setError(error?.message || 'Failed to update client');
              if (error?.code === 'EMAIL_ALREADY_EXISTS') {
                form.setFields([
                  {
                    name: 'email',
                    errors: ['This email is already in use']
                  }
                ]);
              }
            }
          }
        });
      } catch (error: any) {
        setError(error?.message || 'Failed to validate email');
      }
    };

    return (
      <div className="GeneralInformation">
        {error && <Alert type="error" message={error} className="error-alert" />}
        
        <div className="info-section">
          <BaseText type="subHeading">Personal Information</BaseText>

          <Row gutter={[24, 16]} className="info-grid">
            <Col xs={24} sm={12}>
              <div className="info-item">
                <BaseText type="body2" className="label">Full Name</BaseText>
                <BaseText>{`${info.firstName} ${info.lastName}`}</BaseText>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="info-item">
                <BaseText type="body2" className="label">Email</BaseText>
                {isEditingEmail ? (
                  <Form
                    form={form}
                    initialValues={info}
                    onFinish={handleSubmit}
                    className="inline-edit-form"
                  >
                    <Form.Item 
                      name="email" 
                      style={{ marginBottom: 0 }}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[{ validator: validateEmail }]}
                      validateFirst={true}
                      hasFeedback
                      validateStatus={emailValidateStatus}
                    >
                      <Input 
                        placeholder="Enter email address"
                        autoComplete="email"
                        onChange={handleEmailChange}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    <Space style={{ marginTop: '8px' }}>
                      <Button 
                        type="primary" 
                        htmlType="submit"
                        size="small"
                        disabled={
                          emailValidateStatus !== 'success' ||
                          form.getFieldsError().some(({ errors }) => errors.length)
                        }
                      >
                        Save
                      </Button>
                      <Button 
                        size="small"
                        onClick={() => {
                          setIsEditingEmail(false);
                          setError(undefined);
                          form.resetFields();
                        }}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </Form>
                ) : (
                  <Space>
                    <BaseText>{info.email}</BaseText>
                    <Button 
                      type="link" 
                      size="small"
                      onClick={() => setIsEditingEmail(true)}
                      style={{ padding: '0', height: 'auto' }}
                    >
                      Edit
                    </Button>
                  </Space>
                )}
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="info-item">
                <BaseText type="body2" className="label">Phone</BaseText>
                <BaseText>{info.phone}</BaseText>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="info-item">
                <BaseText type="body2" className="label">Created Date</BaseText>
                <BaseText>{info.createdAt}</BaseText>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const refreshData = () => {
    if (clientId) {
      dispatch(getIndividualClientInfo({ clientId }))
        .unwrap()
        .then(setGeneralInformation)
        .catch(console.error);
    }
  };

  const tabItems = INDIVIDUAL_CLIENT_DETAIL_TAB.map((tab) => {
    switch (tab.key) {
      case EIndividualClientDetailTabKey.GENERAL_INFORMATION:
        return {
          ...tab,
          children: generalInformation ? (
            <GeneralInformation 
              info={generalInformation} 
              onUpdate={refreshData}
            />
          ) : (
            <Spin size="large" />
          ),
        };

      case EIndividualClientDetailTabKey.PRACTITIONERS:
        return {
          ...tab,
          children: generalInformation?.practitioners ? (
            <PractitionersTable practitioners={generalInformation.practitioners} />
          ) : (
            <Spin size="large" />
          ),
        };

      default:
        return tab;
    }
  });

  useEffect(() => {
    const fetchClientInfo = async () => {
      try {
        const response = await dispatch(getIndividualClientInfo({ clientId }));
        const clientInfo = unwrapResult(response);
        setGeneralInformation(clientInfo);
      } catch (error) {
        console.error('Failed to fetch client info:', error);
      }
    };

    if (clientId) {
      fetchClientInfo();
    }
  }, [clientId, dispatch]);

  return (
    <Container className="IndividualClientDetail">
      <CommonContent
        title={
          <>
            <Breadcrumb className="IndividualClientDetail__breadcrumb">
              <Breadcrumb.Item>
                <span onClick={() => navigate(-1)}>← Back to Clients</span>
              </Breadcrumb.Item>
            </Breadcrumb>
            <Space size={16} className="IndividualClientDetail__title">
              <BaseText type="display1">
                {generalInformation ? 
                  `${generalInformation.firstName} ${generalInformation.lastName}` : 
                  'Loading...'}
              </BaseText>
              {generalInformation?.status && (
                <StatusBox status={generalInformation.status} />
              )}
            </Space>
          </>
        }
        className="IndividualClientDetail__wrapper"
      >
        <Tabs 
          items={tabItems} 
          activeKey={currentTabKey} 
          onChange={handleChangeTab} 
        />
      </CommonContent>
    </Container>
  );
};

export default IndividualClientDetail; 