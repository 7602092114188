export enum EClientsAction {
  GET_CLIENTS = 'GET_CLIENTS',
  GET_CLINIC_GENERAL_INFORMATION = 'GET_CLINIC_GENERAL_INFORMATION',
  GET_CLINIC_DETAIL_STATISTIC = 'GET_CLINIC_DETAIL_STATISTIC',
  GET_SUBSCRIPTION_INFO = 'GET_SUBSCRIPTION_INFO',
  GET_SUBSCRIPTION_CHANGE_LOG = 'GET_SUBSCRIPTION_CHANGE_LOG',
  GET_SUBSCRIPTION_PAYMENT_LOG = 'GET_SUBSCRIPTION_PAYMENT_LOG',
  GET_INVOICE_DETAIL = 'GET_INVOICE_DETAIL',
  GET_INDIVIDUAL_CLIENT_INFO = 'clients/GET_INDIVIDUAL_CLIENT_INFO',
  UPDATE_CLIENT_INFO = 'clients/UPDATE_CLIENT_INFO',
}
