import { Table, Tag, Space, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import StatusBox from 'src/components/status-box';
import './PractitionersTable.scss';

interface Practitioner {
  id: string;
  email: string;
  status: string;
  createdAt: string;
  practiceName?: string;
  practitionerFirstName: string;
  practitionerLastName: string;
}

const PractitionersTable = ({ practitioners }: { practitioners: Practitioner[] }) => {
  const columns: ColumnsType<Practitioner> = [
    {
      title: 'Name',
      key: 'name',
      fixed: 'left',
      render: (_, record) => (
        <Space direction="vertical" size={0}>
          <Typography.Text strong>
            {`${record.practitionerFirstName} ${record.practitionerLastName}`}
          </Typography.Text>
          {record.practiceName && (
            <Typography.Text type="secondary" className="practice-name">
              {record.practiceName}
            </Typography.Text>
          )}
        </Space>
      ),
      width: 200,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email) => (
        <Typography.Text copyable>{email}</Typography.Text>
      ),
      width: 250,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <StatusBox status={status} />,
      width: 120,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => (
        <Typography.Text>
          {format(new Date(date), 'MMM dd, yyyy')}
        </Typography.Text>
      ),
      width: 150,
    }
  ];

  return (
    <div className="practitioners-table">
      <Table
        columns={columns}
        dataSource={practitioners}
        rowKey="id"
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} practitioners`,
        }}
        scroll={{ x: 'max-content' }}
        className="responsive-table"
      />
    </div>
  );
};

export default PractitionersTable; 