import { Suspense } from 'react';
import { Outlet, Route, Routes as ReactRoutes } from 'react-router-dom';

import AuthLayout from 'src/layouts/auth/AuthLayout';
import { RoutePaths } from './routes-constants';
import Home from 'src/pages/home/Home';
import { getItem } from 'src/utils/storage-utils';
import AddNew from 'src/pages/homework/AddNew/AddNew';
import { EAuthToken } from 'src/variables/storage';
import { HomeworkLayout } from 'src/layouts/homework';
import Videos from 'src/pages/homework/Videos/Videos';
import Activities from 'src/pages/homework/Activities/Activities';
import Questionnaires from 'src/pages/homework/Questionnaires/Questionnaires';
import WrittenTasks from 'src/pages/homework/WrittenTasks/WrittenTasks';
import NonAuthorizedRoutes from './NonAuthorizedRoutes';
import SignInPage from 'src/pages/non-auth/sign-in/SignInPage';
import Clients from 'src/pages/clients/Clients';
import ClientDetail from 'src/pages/clients/ClientDetail';
import QuotesOfTheDay from 'src/pages/quotes-of-the-day/QuotesOfTheDay';
import PricingPlanPage from 'src/pages/pricing-plan';
import PsychoeducationPage from 'src/pages/psychoeducation/PsychoeducationPage';
import ProfilePage from 'src/pages/profile';
import ForgotPasswordPage from 'src/pages/non-auth/forgot-password/ForgotPasswordPage';
import ResetPassword from 'src/pages/non-auth/forgot-password/ResetPassword';
import VerifyOTPPage from 'src/pages/non-auth/verify-otp/VerifyOTPPage';
import IndividualClientDetail from 'src/pages/clients/IndividualClientDetail';

const Routes = () => {
  const accessToken = getItem(EAuthToken.ACCESS_TOKEN);

  return (
    <Suspense>
      <ReactRoutes>
        <Route element={<NonAuthorizedRoutes isAuthenticated={!!accessToken} />}>
          <Route path={RoutePaths.SIGN_IN} element={<SignInPage />} />
          <Route path={RoutePaths.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
          <Route path={RoutePaths.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={RoutePaths.VERIFY_OTP} element={<VerifyOTPPage />} />
        </Route>
        <Route element={<AuthLayout isAuthenticated={!!accessToken} />}>
          <Route path={RoutePaths.HOME} element={<Home />} />
          <Route path={RoutePaths.PROFILE} element={<ProfilePage />} />
          <Route
            path={RoutePaths.HOMEWORK}
            element={
              <HomeworkLayout>
                <Outlet />
              </HomeworkLayout>
            }
          >
            <Route path={RoutePaths.HOMEWORK_QUESTIONNAIRES} element={<Questionnaires />} />
            <Route path={`${RoutePaths.HOMEWORK_ACTIVITIES}/:topicId`} element={<Activities />} />
            <Route path={`${RoutePaths.HOMEWORK_WRITTEN_TASKS}/:topicId`} element={<WrittenTasks />} />
            <Route path={`${RoutePaths.HOMEWORK_VIDEOS}/:topicId`} element={<Videos />} />
            <Route path={`${RoutePaths.HOMEWORK_QUESTIONNAIRES}/:topicId`} element={<Questionnaires />} />
          </Route>
          <Route path={RoutePaths.HOMEWORK_ADD_NEW} element={<AddNew />} />
          <Route path={`${RoutePaths.HOMEWORK_ADD_NEW}/:homeworkId`} element={<AddNew />} />
          <Route path={RoutePaths.CLIENTS} element={<Clients />} />
          <Route path={RoutePaths.CLIENT_DETAIL()} element={<ClientDetail />} />
          <Route path={RoutePaths.INDIVIDUAL_CLIENT_DETAIL()} element={<IndividualClientDetail />} />
          <Route path={RoutePaths.PSYCHO_EDUCATION} element={<PsychoeducationPage />} />
          <Route path={RoutePaths.QUOTES_OF_THE_DAY} element={<QuotesOfTheDay />} />
          <Route path={RoutePaths.PRICING_PLAN} element={<PricingPlanPage />} />
        </Route>
      </ReactRoutes>
    </Suspense>
  );
};

export default Routes;
